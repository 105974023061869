import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { prototype } from 'stream';
import Popup from './Popup';
import { UserContext } from './Nextcontext';


class Ref extends React.Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      showPopup: false,
      reference: props.reference,
      comptinfo: props.comptinfo,
      toutref: props.toutref,
      questionId: props.questionId,
      suite: props.suite,

    };
  }

  togglePopup() {
    this.setState({
      showPopup: !this.state.showPopup
    });
  }
  componentWillReceiveProps(props) {
    this.setState({ reference: props.reference });
    this.setState({ comptinfo: props.comptinfo });
    this.setState({ toutref: props.toutref });
    this.setState({ questionId: props.questionId });
    this.setState({ suite: props.suite });
  }

  componentDidUpdate() {

    var el1 = document.getElementsByClassName('ref');
    if (this.context.nextsuitechap == 2) {
      el1[0].style.opacity = "0";
    }

  }

  render() {

    return (
      <UserContext.Consumer>
        {value => (

          <div>

            <div className="ref"
              onClick={this.togglePopup.bind(this)}>
            </div>


            <div>

              {this.state.showPopup &&
                <Popup
                  infoglobal={this.state.reference[value.nextsuitechap + 1]}
                  closePopup={this.togglePopup.bind(this)}
                />
              }
            </div>



          </div>
        )}
      </UserContext.Consumer>
    );
  }
}

export default Ref;
