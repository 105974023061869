import React, { createContext, Component } from "react";

/*****************the context************************** */
export const UserContext = createContext({
    index: false,
    nextsuitechap: -1,
    choix_fiche: -1,
    contentsFiche2: [],
    contentsFiche3: [],
    contentsFiche4: [],
    toggleindex: () => { },
    incrimente: () => { },
    choix: () => { },
    remplir_contentsFiche2: () => { },
    remplir_contentsFiche3: () => { },
    remplir_contentsFiche4: () => { }

});
/*****************the context provider ************************** */

class Nextcontext extends Component {
    constructor(props) {
        super(props);

        /******************** contain of examen clinique onglet in popup fiche **************************** */
        this.remplir_contentsFiche2 = (contents) => {
            this.setState(state => ({
                contentsFiche2: [...this.state.contentsFiche2, contents]
            }));
        };

        /******************** contain of prise_en charge **************************** */
        this.remplir_contentsFiche3 = (contents) => {
            this.setState(state => ({
                contentsFiche3: [...this.state.contentsFiche3, contents]
            }));
        };

        /******************** contain of examen complementaire onglet in popup fiche **************************** */

        this.remplir_contentsFiche4 = (contents) => {
            this.setState(state => ({
                contentsFiche4: [...this.state.contentsFiche4, contents]
            }));
        };
        this.choix = (x) => {
            this.setState(state => ({
                choix_fiche: x
            }));
        };
        /*****************button suite to next chapter ********************** */
        this.incrimente = () => {
            this.setState(state => ({
                nextsuitechap: this.state.nextsuitechap + 1
            }));
        };
        /*****************button suivant to next chapter ********************** */

        this.toggleindex = () => {
            this.setState(state => ({
                index: !state.index
            }));
        };


        this.state = {
            index: false,
            nextsuitechap: -1,
            choix_fiche: -1,
            contentsFiche2: [],
            contentsFiche3: [],
            contentsFiche4: [],
            toggleindex: this.toggleindex,
            incrimente: this.incrimente,
            choix: this.choix,
            remplir_contentsFiche2: this.remplir_contentsFiche2,
            remplir_contentsFiche3: this.remplir_contentsFiche3,
            remplir_contentsFiche4: this.remplir_contentsFiche4,

        };
    };

    render() {
        return (
            <UserContext.Provider value={this.state}>
                {this.props.children}
            </UserContext.Provider>
        );
    }
}

export default Nextcontext;