//info : info in bubble when click qcm or click
//isInfo : true si il y a info
//infoglobal : info in Info Button
//toutref : all infoglobal in Info Button
//type : 'narrator'(bulle without arrow) || 'qcm' || 'click'
export const QuizData = [
  {
    id: 0,
    chapitre: 0,
    type: '',
    bg: 'patient',
    question: `Bienvenue Docteur. Jouez au « Cas clinique » et trouvez le bon diagnostic et le traitement le plus adapté pour guérir votre patient !`,
    suite: [],
    answer: [],
    options: [],
    info: [],
    answer: [],
    infoglobal: [],
    reference: [],
    toutref: "",
  },
  {
    id: 1,
    chapitre: 0,
    type: 'narrator',
    bg: 'patient',
    question: `Cet après-midi, Madame Sana se présente chez le
    médecin pour une consultation.`,
    suite: [],
    options: [],
    info: [],
    answer: [],
    infoglobal: [],
    reference: [],
    toutref: [],

  },
  {
    id: 2,
    chapitre: 0,
    type: '',
    bg: 'patient',
    reference: [],
    toutref: "",
    question: `Bonjour Docteur, je m’appelle Sana. J’ai 25 ans et je
    travaille dans une pâtisserie. Pour être honnête,
    depuis 6 ans je mange beaucoup de gâteaux et je ne
    me brosse pas les dents tous les jours.`,
    suite: [
      `J'ai eu une extraction d'une dent de sagesse il y a
      5 mois après une douleur intense et un gonflement
      du visage.`,
      `Aujourd’hui, je viens vous voir car j’ai un mal de
      dents horrible et j’ai de la fièvre. Comme vous
      pouvez le voir, mon visage est gonflé et c’est très
      embarrassant.`,



    ],
    options: [],
    info: [],
    answer: [],
    infoglobal: [],
  },
  {
    suivant: true,
    id: 3,
    chapitre: 0,
    type: '',
    bg: 'patient',
    question: `Depuis quelques jours, je ne mange pas bien et j’ai
    très mal quand je mange des aliments froids.`,
    suite: [],
    options: [],
    info: [],
    answer: [],
    infoglobal: [],
    reference: [],
    toutref: [],
  },
  {
    id: 4,
    chapitre: 1,
    type: 'click',
    bg: 'medecin',
    reference: [],
    toutref: "",
    answer: [],
    clinique: [],
    question: `Docteur, en cliquant sur les différents examens, vous
    pouvez consulter les résultats.`,
    suite: [],
    options: [`EXAMEN DE LA CAVITÉ BUCCALE`, `NFS`, `RADIOGRAPHIE`, `TEMPÉRATURE`],

    info: [
      `À l'examen de la cavité buccale, la dent suspectée
      était cariée et entourée d'un érythème et d'un
      oedème gingivaux. En plus, il y avait un abcès
      localisé à l'apex.`,

      `Docteur, on note une hyperneutrophilie.`,

      `Il n’y a pas de compression ou de déviation de la
      trachée.`,

      `L’examen de Sana note une température à 38,5°C.`,

    ],


    infoglobal: [
      `<div class='titre'>Examen de la cavité buccale</div>
    <div class='info-popup'> 
    Lors de l'examen de la cavité buccale, en cas de suspicion d'abcès dentaire, la ou les dents suspectes
    d'infection peuvent être décolorées, présenter des altérations de l'émail ou être entourées d'un
    érythème et d'un oedème gingivaux.<sup>[1]</sup>
    </div>
    <div class ='reference'>
    <div class='sous_titre2'> Références </div> 
    <b>1.</b>Sanders JL, Houck RC. Dental Abscess. [En ligne, consulté le 01/04/2021].</br>
    Disponible sur :  <a target="_blank" href="https://www.ncbi.nlm.nih.gov/books/NBK493149/">https://www.ncbi.nlm.nih.gov/books/NBK493149/</a>
    </div>
   `
      ,
      ` <div class='titre'>NFS</div>
      <div class='info-popup'> 
      La Numération de la Formule Sanguine (NFS) peut aider à déterminer l'étiologie infectieuse à partir du
  nombre total de leucocytes et de la lignée prédominante dans cette formule leucocytaire.<sup>[1]</sup></br>
  Pour les infections bactériennes, il devrait y avoir une hyperneutrophilie chez les personnes
  immunocompétantes.<sup>[1]</sup>
      </div>
      <div class ='reference'>
      <div class='sous_titre2'> Références </div> 
      <b>1.</b>Sanders JL, Houck RC. Dental Abscess. [En ligne, consulté le 01/04/2021].</br>
      Disponible sur :  <a target="_blank" href="https://www.ncbi.nlm.nih.gov/books/NBK493149/">https://www.ncbi.nlm.nih.gov/books/NBK493149/</a>
      </div>
   `,
      `<div class='titre'>Radiographie</div>
    <div class='info-popup'> 
    La radiographie de la tête et du cou peut aider à identifier une éventuelle compression ou déviation
    trachéale mais aussi un emphysème sous-cutané d’un tissu nécrotique. Elle peut être utile également
    pour évaluer une éventuelle dissémination bactérienne ascendante vers les cavités sinusales.<sup>[1]</sup>
    </div>

    <div class ='reference'>
    <div class='sous_titre2'>Références </div> 
    <b>1.</b> Sanders JL, Houck RC. Dental Abscess. [En ligne, consulté le 01/04/2021].</br>
    Disponible sur : <a target="_blank" href="https://www.ncbi.nlm.nih.gov/books/NBK493149/">https://www.ncbi.nlm.nih.gov/books/NBK493149/</a>
    </div>
   `,
      `<div class='titre'>Température</div>
    <div class='info-popup'> 
    La mesure de la température corporelle est un examen important dans ce cas pour établir le diagnostic
    et décider de la prise en charge.<sup>[1]</sup>
    </div>
    
    <div class='reference'>
    <div class='sous_titre2'> Références </div> 
    <b>1.</b> Sanders JL, Houck RC. Dental Abscess. [En ligne, consulté le 01/04/2021].</br>
      Disponible sur : <a target="_blank" href="https://www.ncbi.nlm.nih.gov/books/NBK493149/">https://www.ncbi.nlm.nih.gov/books/NBK493149/</a>
    </div>`,
    ],
  },
  {
    id: 5,
    chapitre: 2,

    type: 'qcm',

    bg: 'medecin',
    reference: [],

    question: `D’après vous docteur, quel est le diagnostic à retenir <span class='interogation'>?</span>`,
    suite: [],
    options: [`Abcès parodontal`, `Abcès péricoronaire`, `Abcès périapical`, `Abcès gingival`],
    answer: [`Abcès périapical`],
    info: [
      `En fait Docteur, la localisation de l’abcès exclut ce
      diagnostic.`,
      `En fait Docteur, les signes et les examens cliniques ne
      sont pas en faveur d’un abcès péricoronaire.`,
      `En effet Docteur, le diagnostic à retenir est celui
      d’abcès périapical.`,
      `En fait Docteur, les signes et l’examen clinique ne sont
      pas en faveur d’un abcès gingival.`],
    infoglobal: [
      `<div class='titre'>Abcès parodontal</div>
    <div class='info-popup'>
    Les abcès parodontaux surviennent le long d'une dent en tant que complication d'une maladie
    parodontale avancée.<sup>[2]</sup>
    </div>

    <div class ='reference'>
    <div class='sous_titre2'> Références </div> 
    <b>2.</b> BMJ Best practice. Dental abcess. [En ligne, consulté le 02/04/2021].</br>
    Disponible sur :<a  target="_blank" href="https://bestpractice.bmj.com/topics/en-gb/1206"> https://bestpractice.bmj.com/topics/en-gb/1206</a></br>
    </div>
   `
      ,

      `<div class='titre'>Abcès péricoronaire </div>
    <div class='info-popup'>
    L'abcès péricoronaire implique le tissu gingival entourant la couronne d'une dent partiellement en
    éruption.<sup>[2]</sup>
    </div>

    <div class ='reference'>
    <div class='sous_titre2'> Références </div> 
    <b>2.</b> BMJ Best practice. Dental abcess. [En ligne, consulté le 02/04/2021].</br>
    Disponible sur :<a target="_blank" href="https://bestpractice.bmj.com/topics/en-gb/1206"> https://bestpractice.bmj.com/topics/en-gb/1206</a></br>
    </div>
    `
      ,

      `<div class='titre'>Abcès dentaires</div>
    <div class='info-popup'>
    Les abcès parodontaux surviennent le long d'une dent en tant que complication d'une maladie
parodontale avancée.<sup>[2]</sup> </br>
L'abcès péricoronaire implique le tissu gingival entourant la couronne d'une dent partiellement en
éruption.<sup>[2]</sup></br>
Les abcès périapicaux représentent le type d'abcès dentaires le plus courant. Avec une prévalence de
25%, l'abcès périapical aigu constitue le motif le plus fréquent de consultation en urgence chez les
patients souffrant d'infections odontogènes. L'infection résulte d'une inflammation et d'une nécrose de
la pulpe dentaire, à la suite d'une carie dentaire non traitée ou d'un traumatisme.<sup>[2,3]</sup></br>
L'abcès gingival est une infection localisée du tissu gingival, siégeant à proximité du bord gingival ou
de la papille interdentaire. Il n'affecte pas la dent elle-même ni le ligament parodontal.<sup>[2]</sup>
    </div>

    <div class ='reference'>
    <div class='sous_titre2'> Références </div> 
    <b>2.</b> BMJ Best practice. Dental abcess. [En ligne, consulté le 02/04/2021].</br>
    Disponible sur :<a  target="_blank" href="https://bestpractice.bmj.com/topics/en-gb/1206"> https://bestpractice.bmj.com/topics/en-gb/1206</a></br>
    <b>3.</b> López-Píriz R et al. Med Oral Patol Oral Cir Bucal. 2007 Mar 1;12(2):E154-9.</b>
</div>
   `
      ,

      `<div class='titre'>Abcès gingival</div>
    <div class='info-popup'>
    L'abcès gingival est une infection localisée du tissu gingival, siégeant à proximité du bord gingival ou
    de la papille interdentaire. Il n'affecte pas la dent elle-même ni le ligament parodontal.<sup>[2]</sup>
    </div>

    <div class ='reference'>
    <div class='sous_titre2'> Références </div> 
    <b>2.</b> BMJ Best practice. Dental abcess. [En ligne, consulté le 02/04/2021].</br>
    Disponible sur :<a target="_blank" href="https://bestpractice.bmj.com/topics/en-gb/1206"> https://bestpractice.bmj.com/topics/en-gb/1206</a></br>
    </div>
    </div> 
   ` ],

    toutref: [],
  },
  {
    id: 6,
    chapitre: 3,
    type: 'qcm',
    bg: 'medecin',
    reference: [],
    question: `Quelle sont les bactéries les plus courantes lors des
    abcès dentaires ?`,
    suite: [],
    options: [`Anaérobies facultatifs à Gram positif`, `Anaérobies à Gram négatif`, `Bacilles acido-alcoolo-résistants`, 'Anaérobies à Gram positif'],
    answer: [`Anaérobies à Gram négatif`],
    info: [
      `En fait Docteur, les germes anaérobies facultatifs à
      Gram positif sont fréquents dans les abcès dentaires
      mais ne sont pas les plus courants.`,
      `En effet Docteur, les germes anaérobies à Gram
      négatif sont les bactéries les plus fréquentes lors des
      abcès dentaires.`,
      `En fait Docteur, les bacilles acido-alcoolo-résistants
      sont fréquents dans d’autres maladies comme la
      tuberculose.`,
      `En fait Docteur, les germes anaérobies à Gram positif
      sont fréquents dans les abcès dentaires mais ne sont
      pas les plus courants.`],
    infoglobal: [
      `<div class='titre'>Anaérobies facultatifs à Gram positif</div>
    <div class='info-popup'>
    Les anaérobies facultatifs à Gram positif sont des bactéries courantes lors des infections odontogènes,
    y compris les abcès dentaires.</br>
    <b>Exemples d'anaérobies facultatifs à Gram positif :</b></br>
    Streptocoques viridans</br>
    Staphylocoques à coagulase négative, y compris Staphylococcus epidermidis<sup>[4-9]</sup>
    </div>

    <div class ='reference'>
    <div class='sous_titre2'> Références </div> 
    <b>4.</b> De Sousa EL et al. Oral Surg Oral Med Oral Pathol Oral Radiol Endod 2003;96(3):332–339. </br>
    <b>5.</b> Siqueira JF et al. Clin Microbiol Rev 2013;26(2):255–273.</br>
    <b>6.</b> Benachinmardi KK et al. J Lab Physicians 2015;7(1):49–54.</br>
    <b>7.</b> Shweta K et al. Dent Res J 2013;10(5):585–591.</br>
    <b>8.</b> Bahl R et al. Contemp Clin Dent 2014;5(3):307–311.</br>
    <b>9.</b> Rôças IN et al. PLoS One 2018;13(1):e0190469.</br>
    </div>
   `
      ,

      `<div class='titre'>Bactéries courantes lors des infections odontogènes</div>
    <div class='info-popup'>
    Les bactéries courantes lors des infections odontogènes, y compris les abcès dentaires :<sup>[4-9]</sup></br>
    <b>Anaérobies facultatifs à Gram positif :</b></br>
    - <i>Streptocoques viridans</i></br>
    - <i>Staphylocoques</i> à coagulase négative, y compris <i>Staphylococcus epidermidis</i></br>
    <b>Anaérobies à Gram positif :</b></br>
    - <i>Peptostreptococcus</i> spp.</br>
    - Streptocoques anaérobies</br>
    - <i>Parvimonas</i> spp</br>
    <b>Anaérobies à Gram négatif :</b> sont souvent la principale classe de bactéries lors des infections
    dentaires, représentant jusqu'à 80% des isolats.<sup>[6,8]</sup></br>
    - <i>Fusobacterium</i> spp.</br>
    - <i>Prevotella</i> spp.</br>
    - <i>Bacteroides</i> spp.</br>
    - <i>Dialister</i> spp.</br>
    - <i>Porphyromonas</i> spp.</br>
    - <i>Treponema</i> spp.</br>
    Les bacilles acido-alcoolo-résistants communes d'importance médicale comprennent <i>Mycobacterium
    tuberculosis, Mycobacterium leprae</i> et les espèces <i>Nocardia</i>.<sup>[10]</sup></br>
    Ces bactéries provoquent certaines maladies comme la tuberculose, la lèpre et la nocardose.<sup>[10]</sup>
    </div>

    <div class ='reference'>
    <div class='sous_titre2'> Références </div> 
    <b>4.</b> De Sousa EL et al. Oral Surg Oral Med Oral Pathol Oral Radiol Endod 2003;96(3):332–339. </br>
    <b>5.</b> Siqueira JF et al. Clin Microbiol Rev 2013;26(2):255–273.</br>
    <b>6.</b> Benachinmardi KK et al. J Lab Physicians 2015;7(1):49–54.</br>
    <b>7.</b> Shweta K et al. Dent Res J 2013;10(5):585–591.</br>
    <b>8.</b> Bahl R et al. Contemp Clin Dent 2014;5(3):307–311.</br>
    <b>9.</b> Rôças IN et al. PLoS One 2018;13(1):e0190469.</br>
    <b>10.</b> Bayot ML, Mirza TM, Sharma S. Acid Fast Bacteria. [En ligne, consulté le 02/04/2021].</br>
    Disponible sur :<a target="_blank" href="https://www.ncbi.nlm.nih.gov/books/NBK537121/"> https://www.ncbi.nlm.nih.gov/books/NBK537121/</a></br>
    </div>
   `
      ,

      `<div class='titre'>Bacilles acido-alcoolo-résistants</div>
    <div class='info-popup'>  
    Les bacilles acido-alcoolo-résistants les plus fréquents sont Mycobacterium tuberculosis,
    Mycobacterium leprae et les espèces Nocardia.<sup>[10]</sup></br>
    Ces bactéries provoquent certaines maladies comme la tuberculose, la lèpre et la nocardose.<sup>[10]</sup>
    </div>

    <div class ='reference'>
    <div class='sous_titre2'> Références </div> 
    <b>10.</b> Bayot ML, Mirza TM, Sharma S. Acid Fast Bacteria. [En ligne, consulté le 02/04/2021].</br>
    Disponible sur :<a target="_blank" href="https://www.ncbi.nlm.nih.gov/books/NBK537121/"> https://www.ncbi.nlm.nih.gov/books/NBK537121/</a></br>
    </div>
   `
      ,

      `<div class='titre'>Anaérobies à Gram positif</div>
    <div class='info-popup'>
    Les anaérobies à Gram positif sont des bactéries courantes lors des infections odontogènes, y compris
    les abcès dentaires.</br>
    <b>Exemples d'anaérobies à Gram positif :</b></br>
    - <i>Peptostreptococcus</i> spp.</br>
    - Streptocoques anaérobies</br>
    - <i>Parvimonas</i> spp.<sup>[4-9]</sup>
    </div>

    <div class ='reference'>
    <div class='sous_titre2'> Références </div> 
    <b>4.</b> De Sousa EL et al. Oral Surg Oral Med Oral Pathol Oral Radiol Endod 2003;96(3):332–339. </br>
    <b>5.</b> Siqueira JF et al. Clin Microbiol Rev 2013;26(2):255–273.</br>
    <b>6.</b> Benachinmardi KK et al. J Lab Physicians 2015;7(1):49–54.</br>
    <b>7.</b> Shweta K et al. Dent Res J 2013;10(5):585–591.</br>
    <b>8.</b> Bahl R et al. Contemp Clin Dent 2014;5(3):307–311.</br>
    <b>9.</b> Rôças IN et al. PLoS One 2018;13(1):e0190469.</br>
    </div>
   `],
    toutref: [
    ],
  },
  {
    id: 7,
    chapitre: 4,
    type: 'qcm',
    bg: 'medecin',
    reference: [],
    question: `Docteur, quelles stratégies thérapeutiques
    recommanderiez-vous pour traiter cette patiente ?`,
    suite: [`Bravo Docteur, le traitement doit être basé sur le
    contrôle de la douleur et sur la prévention du risque
    de propagation de l'infection.`],
    options: [`Analgésiques`, `Hospitalisation`, `Drainage`, `Élimination de caries`, 'Antibiothérapie'],
    info: [
      `En effet Docteur, le contrôle de la douleur est
      fondamental dans ce cas.`,

      `En fait Docteur, l’hospitalisation dans ce cas n’est pas
      nécessaire.`,

      `En effet Docteur, un drainage de l'abcès doit être
      effectué.`,

      `En effet Docteur, les caries doivent être enlevées.`,

      `En effet Docteur, des antibiotiques doivent être
      prescrits en cas de propagation de l'infection.`
    ],
    answer: [`Analgésiques`, `Drainage`, `Élimination de caries`, 'Antibiothérapie'],
    infoglobal: [
      `<div class='titre'>Analgésiques</div>
    <div class='info-popup'>
    La douleur associée à l’abcès périapical est mieux traitée avec des analgésiques. Aucun autre
    médicament connu n'a le potentiel de réduire la douleur. Les AINS sont prescrits comme traitement de
    première intention. En cas de contre-indication aux AINS, le paracétamol est prescrit.<sup>[11]</sup></br>
    Les AINS et le paracétamol peuvent être utilisés efficacement en association dans un schéma
    posologique de deux heures. Si cela ne permet pas de soulager suffisamment la douleur, des
    analgésiques opioïdes peuvent être nécessaires.<sup>[11]</sup>
    </div>

    <div class ='reference'>
    
    <div class='sous_titre2'> Références </div>
    <b>11.</b>Jonasson P et al. Nor Tannlegeforen Tid 2016;126:20–26.
    </div>
   `

      ,
      `<div class='titre'>Hospitalisation</div>
    <div class='info-popup'> 
    Les abcès dentaires peuvent être pris en charge en ambulatoire, sans recourir à l'hospitalisation ni à
    l'administration d'antibiotiques intraveineux (IV) à moins que le patient présente des symptômes telles
    que la fièvre, la dyspnée ou l’obstruction des voies respiratoires.<sup>[1]</sup>
    </div>

    <div class ='reference'>
    <div class='sous_titre2'> Références </div>
    <b>1.</b>Sanders JL, Houck RC. Dental Abscess. [En ligne, consulté le 01/04/2021].</br>
    Disponible sur :  <a target="_blank" href="https://www.ncbi.nlm.nih.gov/books/NBK493149/">https://www.ncbi.nlm.nih.gov/books/NBK493149/</a>
    </div>
   `
      ,

      `<div class='titre'>Drainage</div>
    <div class='info-popup'>  
    Un drainage doit toujours être réalisé en cas de tuméfaction et de collection de pus dans les tissus mous
afin de réduire le risque de propagation infectieuse et pour soulager la douleur.<sup>[11]</sup>
    </div>
    <div class ='reference'>
    <div class='sous_titre2'> Références </div>
    <b>11.</b>Jonasson P et al. Nor Tannlegeforen Tid 2016;126:20–26.
    
    `
      ,

      `<div class='titre'>Élimination de caries</div>
    <div class='info-popup'>
    La carie est la principale cause d'infection pulpaire. Les caries doivent être soignées et le traitement
canalaire doit être effectué dans des conditions aseptiques en utilisant une digue en caoutchouc pour
éviter la diffusion d'autres germes dans le canal radiculaire.<sup>[11]</sup>
    </div>

    <div class ='reference'>
    <div class='sous_titre2'> Références </div>
    <b>11.</b>Jonasson P et al. Nor Tannlegeforen Tid 2016;126:20–26.
    </div>`,

      `<div class='titre'>Antibiothérapie</div>
    <div class='info-popup'>  
  <b> Les antibiotiques ont un rôle certain dans les situations suivantes :<sup>[11]</sup></b></br>
- Impossibilité du drainage</br>
- Preuves de la propagation de l'infection</br>
- Altération de l'état général du patient ou risque élevé d'infection grave tel qu'en cas d'immunodépression
    </div>
    <div class ='reference'>
    <div class='sous_titre2'> Références </div>
    <b>11.</b>Jonasson P et al. Nor Tannlegeforen Tid 2016;126:20–26.
    </div>
     `],

    toutref: [`<div class='titre'>Traitement</div>
    <div class='info-popup'>
    La douleur associée à l’abcès périapical est mieux traitée avec des analgésiques. Aucun autre
    médicament connu n'a le potentiel de réduire la douleur. Des AINS doivent être prescrits, ou du
    paracétamol si les AINS sont contre-indiqués.<sup>[11]</sup></br>
    Les AINS et le paracétamol peuvent être utilisés efficacement en association dans un schéma
    posologique de deux heures. Si cela ne permet pas de soulager suffisamment la douleur, des
    analgésiques opioïdes peuvent être nécessaires.<sup>[11]</sup></br>
    Les abcès dentaires peuvent être pris en charge en ambulatoire, sans recourir à l'hospitalisation ni à
    l'administration d'antibiotiques intraveineux (IV) à moins que le patient présente des symptômes telles
    que la fièvre, la dyspnée ou l’altération des voies respiratoires secondaire à un gonflement.<sup>[1]</sup></br>
    Un drainage doit toujours être réalisé en cas de tuméfaction et de collection de pus dans les tissus mous
    afin de réduire le risque de propagation infectieuse et pour soulager la douleur.<sup>[11]</sup></br>
    La carie est la principale cause d'infection pulpaire. Les caries doivent être soignées et le traitement
    canalaire doit être effectué dans des conditions aseptiques en utilisant une digue en caoutchouc pour
    éviter la diffusion d'autres germes dans le canal radiculaire.<sup>[11]</sup></br>
    <b>Les antibiotiques ont un rôle certain dans les situations suivantes :<sup>[11]</sup></b></br>
    - Impossibilité du drainage</br>
    - Preuves de la propagation de l'infection</br>
    - Altération de l'état général du patient</br>
    - Altération de l'état général du patient ou risque élevé d'infection grave tel qu'en cas d'immunodépression
    </div>

    <div class ='reference'>
    <div class='sous_titre2'> Références </div>
    
    <b>1.</b>Sanders JL, Houck RC. Dental Abscess. [En ligne, consulté le 01/04/2021].</br>
    Disponible sur :  <a target="_blank" href="https://www.ncbi.nlm.nih.gov/books/NBK493149/">https://www.ncbi.nlm.nih.gov/books/NBK493149/</a></br>
    <b>11.</b>Jonasson P et al. Nor Tannlegeforen Tid 2016;126:20–26.

    </div>
   `],
  },
  {
    id: 8,
    type: 'qcm',
    chapitre: 5,
    bg: 'medecin',
    reference: [],
    question: [`Quels sont les critères de choix de l'antibiotique
    approprié dans ce cas ?`],
    suite: [],
    options: [
      `Spectre d'activité antibactérienne`,
      `Données locales de sensibilité aux antibiotiques`,
      `Profil pharmacocinétique`,
      `Risque d'effets indésirables`],
    answer: [`Spectre d'activité antibactérienne`,
      `Données locales de sensibilité aux antibiotiques`,
      `Profil pharmacocinétique`,
      `Risque d'effets indésirables`],
    info: [
      `En effet Docteur, l’antibiotique choisi doit être actif
      contre les microorganismes impliqués dans l'infection.`,
      `En effet Docteur, le choix de l'antibiothérapie devrait
      refléter les tendances locales en matière de sensibilité
      bactérienne.`,
      `En effet Docteur, l'antibiotique devrait répondre aux
      paramètres pharmacocinétiques appropriés tels que
      la bonne pénétration et la bonne diffusion au site
      d'infection.`,
      `En effet Docteur, votre choix doit porter sur
      l'antibiotique avec le meilleur rapport efficacité/effets
      indésirables.`],
    infoglobal: [
      `<div class='titre'>Spectre d'activité antibactérienne</div> 
    <div class='info-popup'>
    La composante ploymicrobienne de l'infection odontogène recommande l'utilisation d'antibiotiques
actifs contre les bactéries aérobies et anaérobies.<sup>[12,13]</sup>
    </div>

    <div class='reference'>
    <div class='sous_titre2'>Références </div> 
    <b>12.</b> Ball P et al. Int Antimicrob Ag 2007;30S:S139-S141.</br>
    <b>13.</b> Bascones Martínez A et al. Oral Med Pathol 2004;9:363–376.
   
    </div>`,

      `<div class='titre'>Données locales de sensibilité aux antibiotiques</div>
      <div class='info-popup'>
      Les données de sensibilité peuvent varier avec le temps et selon les régions. Il faut toujours se référer
      aux données locales de sensibilité avant de prescrire un antibiotique afin d'améliorer les résultats
      thérapeutiques, de réduire le développement de résistances et d'optimiser la rentabilité du
      traitement.<sup>[1-3]</sup>
    </div>

    <div class='reference'>
    <div class='sous_titre2'>Références </div> 
    <b>12.</b> Ball P et al. Int Antimicrob Ag 2007;30S:S139-S141.</br>
<b>13.</b> Bascones Martínez A et al. Oral Med Pathol 2004;9:363–376.</br>
<b>14.</b> Torumkuney D et al. J Antimicrob Chemother 2020; 75 Suppl 1: i2–i18.
    </div>`,


      `<div class='titre'>Profil pharmacocinétique</div> 
    <div class='info-popup'>
    Pour prescrire une antibiothérapie appropriée, les paramètres pharmacocinétiques et
pharmacodynamiques devraient être pris en considération pour le choix de l'antibiotique adéquat et
du schéma posologique le plus efficace.<sup>[12,13]</sup>
    </div>

    <div class='reference'>
    <div class='sous_titre2'> Références </div> 
    <b>12.</b> Ball P et al. Int Antimicrob Ag 2007;30S:S139-S141.</br>
    <b>13.</b> Bascones Martínez A et al. Oral Med Pathol 2004;9:363–376.
    </div>`,


      `<div class='titre'>Risque d'effets indésirables</div>
    <div class='info-popup'>
    L'antibiotique idéal pour traiter une infection devrait satisfaire une série de critères, dont la bonne
tolérance, avec l'association à un minimum d'effets indésirables.<sup>[12]</sup>
    </div>

    <div class='reference'>
    <div class='sous_titre2'>Références </div> 
    <b>12.</b> Ball P et al. Int Antimicrob Ag 2007;30S:S139-S141.
    </div>`],

    toutref: [],
  },
  {
    id: 9,
    chapitre: 6,
    type: 'qcm',
    bg: 'medecin',
    toutref: [],
    reference: [],
    question: `Docteur, sur la base du contexte et des données
    cliniques du patient, quelle serait votre
    antibiothérapie de première intention ?`,
    suite: [],
    options: [`Amoxicilline`, `Azithromycine`, `Métronidazole`, `Amoxicilline/acide clavulanique`],
    answer: [`Amoxicilline/acide clavulanique`],
    info: [
      `En fait Docteur, l’amoxicilline n’est pas recommandée.`,
      `En fait Docteur, l’azithromycine n’est pas
      recommandée.`,
      `En fait Docteur, le métronidazole n’est pas
      recommandé dans ce cas.`,
      `Bravo Docteur, en effet l’amoxicilline/acide
      clavulanique est l’antibiothérapie adéquate pour
      cette patiente.`
    ],
    infoglobal: [
      `<div class='titre'>Amoxicilline</div> 
      <div class='info-popup'>
      Les pénicillines et les céphalosporines peuvent être utilisées dans les infections odontogènes, mais
      l'existence d'une résistance croissante aux antimicrobiens, liée à la production de bêtalactamase,
      rendrait leur utilisation en association avec d'autres antimicrobiens tels que le métronidazole ou
      l'utilisation d'un antibiotique à large spectre plus appropriée.<sup>[1]</sup>
      </div>
      <div class ='reference'>
      <div class='sous_titre2'> Références </div>
      <b>1.</b>Sanders JL, Houck RC. Dental Abscess. [En ligne, consulté le 01/04/2021].</br>
    Disponible sur :  <a target="_blank" href="https://www.ncbi.nlm.nih.gov/books/NBK493149/">https://www.ncbi.nlm.nih.gov/books/NBK493149/</a>
      </div>
     `,
      `
     <div class='titre'>Azithromycine</div> 
      <div class='info-popup'>
      Les macrolides ne doivent pas être utilisés en première intention, sauf si le patient est allergique à la
pénicilline ou aux céphalosporines. Il y a une résistance accrue aux macrolides , impliquant différentes
espèces bactériennes, parmi lesquelles les streptocoques anaérobies et Prevotella, qui colonisent
fortement l'oropharynx et sont souvent la cause d'abcès dentaires.<sup>[1]</sup>
      </div>
      <div class ='reference'>
      <div class='sous_titre2'> Références </div>
      <b>1.</b>Sanders JL, Houck RC. Dental Abscess. [En ligne, consulté le 01/04/2021].</br>
    Disponible sur :  <a target="_blank" href="https://www.ncbi.nlm.nih.gov/books/NBK493149/">https://www.ncbi.nlm.nih.gov/books/NBK493149/</a>
      </div>
     `,
      `
     
     <div class='titre'>Métronidazole</div> 
      <div class='info-popup'>
      Le métronidazole a une excellente couverture contre les anaérobies, cependant il n'a pas une
      couverture suffisante contre les aérobies à Gram positif. Il est recommandé d'utiliser le métronidazole
      en association avec la pénicilline pour étendre la couverture spectrale aux aérobies à Gram positif.<sup>[1]</sup>
      </div>
      <div class ='reference'>
      <div class='sous_titre2'> Références </div>
      <b>1.</b>Sanders JL, Houck RC. Dental Abscess. [En ligne, consulté le 01/04/2021].</br>
    Disponible sur :  <a target="_blank" href="https://www.ncbi.nlm.nih.gov/books/NBK493149/">https://www.ncbi.nlm.nih.gov/books/NBK493149/</a>
      </div>
     `,
      `
     <div class='titre'>Traitement antibiotique</div> 
      <div class='info-popup'>
      La résistance aux antimicrobiens augmente en raison de la production de bêtalactamase. Cette
      augmentation de la résistance rendrait l'utilisation de l'amoxicilline seule non appropriée.<sup>[1]</sup></br>
      Dans une étude ouverte, chez des patients souffrant d’abcès dentaires, d’infections odontogènes ou
      d'actes chirurgicales buccales, l'<b>amoxicilline/acide clavulanique</b> était significativement plus
      efficace que l'amoxicilline.<sup>[15]</sup></br>
      Les macrolides ne doivent pas être utilisés en première intention, sauf si le patient est allergique à la
      pénicilline ou aux céphalosporines. Il y a une résistance accrue aux macrolides et les espèces
      bactériennes qui présentent une résistance sont les streptocoques anaérobies et Prevotella qui sont des
      colonisateurs majeurs de l'oropharynx et souvent responsables d'un abcès dentaire.<sup>[1]</sup></br>
      Le métronidazole a une excellente couverture contre les anaérobies, cependant il n'a pas une
      couverture suffisante contre les aérobies à Gram positif. Il est recommandé d'utiliser le métronidazole
      en association avec la pénicilline pour étendre la couverture antimicrobienne afin d'inclure les
      aérobies à Gram positif.<sup>[1]</sup></br>
      L’<b>amoxicilline/acide clavulanique</b> est un choix efficace pour bien traiter les infections
      odontogènes.[1,16-18]Selon les données combinées d’une méta-analyse de 7 études, 96% des
      pathogènes dentaires sont sensibles à l’<b>amoxicilline/acide clavulnique</b>.<sup>[19]</sup></br>
      L’amoxicilline/acide clavulanique atteint des concentrations adéquates dans le fluide
      créviculaire gingival et offre un large spectre d'activité antibactérienne.<sup>[17,18]</sup></br>
      Son efficacité clinique est prouvée dans le traitement des infections odontogènes aiguës, y compris les
      abcès dentaires sévères.<sup>[16,20]</sup></br>
      L'<b>amoxicilline/acide clavulanique</b> est recommandé comme traitement de choix pour les
      infections odontogènes.<sup>[13]</sup>
      </div>
      <div class ='reference'>
      <div class='sous_titre2'> Références </div>
      <b>1.</b>Sanders JL, Houck RC. Dental Abscess. [En ligne, consulté le 01/04/2021].</br>
    Disponible sur :  <a target="_blank" href="https://www.ncbi.nlm.nih.gov/books/NBK493149/">https://www.ncbi.nlm.nih.gov/books/NBK493149/</a></br>
    <b>13.</b> Bascones Martínez A et al. Oral Med Pathol 2004;9:363–376.</br>
    <b>15.</b> Sulejmanagic N et al. Bos J Basic Med Sci 2005;5(1):61–68.</br>
    <b>16.</b> Tancawan AL et al. Int J Dent 2015;2015:472470.</br>
    <b>17.</b> Résumé des caractéristiques du produit Augmentin® en Tunisie. (Date de révision : Juillet 2015).</br>
    <b>18.</b> Tenenbaum H et al. J Clin Periodontol 1997;24(11)804)807.</br>
    <b>19.</b> Lang PM et al. Int J Antimicrob Agents 2016;48(5)/467-474.</br>
    <b>20.</b> Al-Selivany BJ et al. Jordan Med J 2010;44(3):305-312.
      </div>
     `
    ],
  },
  {
    id: 10,
    type: 'qcm',
    chapitre: 6,
    bg: 'medecin',
    reference: [],
    question: [`Docteur, selon vous, l’ acide clavulanique est :`],
    suite: [`Bravo Docteur, L’acide clavulanique n’a pas d’activité
    antibactérienne propre mais il augmente le spectre
    d’activité de l’amoxicilline.`],
    options: [
      `Une substance bactéricide`,
      `Une substance bactériostatique`,
      `Un inhibiteur irréversible`,
      `Un inhibiteur des pénicillinases`],
    answer: [`Un inhibiteur irréversible`, `Un inhibiteur des pénicillinases`],
    info: [
      `En fait Docteur, l’acide clavulanique n’a pas d’activité
      antibactérienne propre.`,
      `En fait Docteur, l’acide clavulanique n’a pas d’activité
      antibactérienne propre.`,
      `En effet Docteur, l’acide clavulanique est un inhibiteur
      irréversible de bêtalactamase.`,
      `En effet Docteur, l’acide clavulanique permet à
      l’amoxicilline d’être active sur les bactéries devenues
      résistantes aux pénicillines par sécrétion de
      pénicillinases.`],
    infoglobal: [
      `<div class='titre'>Une substance bactéricide</div> 
    <div class='info-popup'>
    
    <div class='blueGreen'><b>Références</b> </div> 
    <b>21.</b> Vaubourdolle M. Infectiologie. Paris : Le Moniteur de l'Internat ; 3ème édition; 2007. p. 182.
     
   
    
    </div>

    `,

      `<div class='titre'>Une substance bactériostatique</div>
    <div class='info-popup'>
    <div class='blueGreen'><b>Références</b> </div> 
    <b>21.</b> Vaubourdolle M. Infectiologie. Paris : Le Moniteur de l'Internat ; 3ème édition; 2007. p. 182.
    </div>

`,

      `<div class='titre'>Un inhibiteur irréversible</div>
<div class='info-popup'>
<div class='blueGreen'><b>Références</b> </div> 
<b>21.</b> Vaubourdolle M. Infectiologie. Paris : Le Moniteur de l'Internat ; 3ème édition; 2007. p. 182.
</div>

`,

      `<div class='titre'>Un inhibiteur des pénicillinases</div>
    <div class='info-popup'>
    L’acide clavulanique est un inhibiteur irréversible de bêtalactamase. Il n’a pas d’activité
antibactérienne propre. Son association renforce considérablement l’efficacité de l’antibiotique de
base en lui permettant d’être actif sur les bactéries devenues résistantes aux pénicillines par sécrétion
de pénicillinases.<sup>[21]</sup>
    </div>

    <div class='reference'>
    <div class='sous_titre2'>Références </div> 
    <b>21.</b>dolle M. Infectiologie. Paris : Le Moniteur de l'Internat ; 3ème édition; 2007. p. 182.
   
    </div>`],

    toutref: [],
  },
  {
    id: 11,
    type: 'conceil',
    bg: 'conceils',
    isInfo: true,
    reference: [],
    question: [],
    suite: [],
    options: [],
    answer: [],
    info: [],
    infoglobal: [`
    <div class='titre'>Conseils à donner pour éviter l’abcès dentaire</div>
    <div class='info-popup'>
    <div class='blueGreen'><b>Références</b> </div> 
    <b>22.</b> L’Assurance Maladie. Les habitudes à prendre pour éviter l’abcès dentaire. [En ligne, consulté le
      28/05/2021].
    </br>
    Disponible sur :
    <a target="_blank" href="https://www.ameli.fr/assure/sante/themes/abces-dentaire/prevention">https://www.ameli.fr/assure/sante/themes/abces-dentaire/prevention</a>
    </div>`,


    ],
    toutref: [],
  },
  {
    id: 12,
    type: 'narrator',
    bg: 'medecin',
    reference: [],
    question: [`Les dentistes prescrivent environ 7 à 11% de
    l'ensemble des antibiotiques courants.<sup>23</sup>`],
    suite: [`Selon une enquête Écossaise : 75% des antibiotiques
    ont été prescrits de manière inappropriée aux patients
    ayant consulté les services d'urgences des cliniques
    dentaires.<sup>24</sup>`,
      `<div style="text-align:center">Les antibiotiques doivent être utilisés
      de façon appropriée :<sup>25,26</sup></div>
      <div style="padding:2%">
      <li>Pour traiter les infections odontogènes </li>
      <li>En complément des interventions endodontiques</li>
      <li>Pour prévenir les complications</li>
      <li>Pour limiter le développement de résistances</li>
      </div>
      `],
    options: [],
    answer: [],
    info: [],
    isInfo: true,
    infoglobal: [`
    <div class='titre'>Rôle des dentistes dans la prescription d'antibiotiques</div>
    <div class='info-popup'>
    <div class='blueGreen'><b>Références</b> </div> 
    <b>23.</b> Dar-Odeh NS et al. Ther Clin Risk Manag. 2010 Jul 21;6:301-306.
    </div>`,

      ` <div class='titre'>Prescription inappropriée des antibiotiques</div>
      <div class='info-popup'>
      <div class='blueGreen'><b>Références</b> </div> 
      <b>24.</b> Dailey YM et al. Br Dent J 2001;191(7):391–393.
      </div>`,


      ` <div class='titre'>Utilisation appropriée des antibiotiques</div>
      <div class='info-popup'>
      <div class='blueGreen'><b>Références</b> </div> 
      <b>25.</b> Bansal R et al. J Family Med Prim Care. 2019;8(11):3518-3524.</br>
      <b>26.</b> Ramu C et al. Asian Pac J Trop Biomed. 2012;2(9):749-754.</br>
      </div>`
    ],
    toutref: [],
  },
  {
    id: 13,
    type: 'qcm',
    //isInfo : true si il y a info
    rejouer: true,
    bg: 'medecin',
    reference: [],
    question: [`Bravo Docteur ! Vous avez terminé le cas clinique
brillamment. Grâce à vous et votre expertise, Sana se
porte bien.
    `],
    suite: [],
    options: [],
    answer: [],
    info: [],
    infoglobal: [],
    adresse: [
      ` <div>
           <div>
      <div class='sous_titre2'> <b>Pour toutes informations complémentaires, veuillez
      contacter GlaxoSmithKline Tunisie :</b> </div>
      <div class="text">
      Immeuble Khairi- New Tower, Lot B17, Chez Regus (7<sup>ème</sup> étage),
      Centre Urbain Nord 1080. Tunis. Tunisie. </br>
      <span class='sous_titre2'>Tél.:</span> +216 71 134 534 ;<span class='sous_titre2'>Fax :</span> +216 71 134 599
      </div>
     
          </div>
     
          <div>
      <div class='sous_titre2'>  <b>Pour toute notification des effets indésirables, veuillez nous contacter par e-mail à l'adresse suivante :</b> </div>
     <div class="text">
      <a href="pharmacovigilance.na@gsk.com">pharmacovigilance.na@gsk.com</a> ou via <a href="www.gskpro.com/fr-tn">www.gskpro.com/fr-tn</a> en cliquant sur « Signaler un effet indésirable »
      </div>
      </div>
        </div>
        <div class="text reg">
        PM-TN-ACA-WCNT-210002 Date de préparation : Août 2022
  </div>
  
        `],
    toutref: [],
  },

];



export const FicheData = [

  {
    type: "fiche1",
    vid: 2,
    fiche: [`<li>Sana, 25 ans</li>
    <li>Régime alimentaire riche en sucres</li>
    <li>Mauvaise hygiène dentaire </li>`],
  },
  {
    type: "fiche1",
    vid: 3,
    fiche: ["<li>Antécédent d’extraction d’une dent de sagesse </li>"],
  }
  ,
  {
    type: "fiche1",
    vid: 4,
    fiche: [`<li>Douleur dentaire</li>
    <li>Fièvre</li>
    <li>Cellulite dentaire</li>`],
  },
  {
    type: "fiche1",
    vid: 5,
    fiche: [`<li>Sensibilité thermique</li>`],
  },
  {
    type: "fiche2",
    vid: 6,
    fiche: [
      `<li>Érythème et œdème gingivaux</li>
      <li>Carie dentaire</li>
      <li>Abcès localisé à l'apex</li>`,
      `<li>Infections bactériennes</li>`,
      ``,
      `<li>T=38,5°C</li>`,


    ],
  }
  ,
  {
    type: "fiche2",
    vid: 7,
    fiche: [` <div style="margin-top:2%">
      Le diagnostic est : <span class="blueGreen">un abcès périapical</span></div> `],

  },
  {
    type: "fiche3",
    vid: 8,
    fiche: [
      "<li>Anaérobies à Gram négatif</li>",
    ],
  },

  {
    vid: 9,
    type: "fiche4",
    fiche: [`
      <li>Analgésiques</li>
      <li>Drainage</li>
      <li>Élimination de caries </li>
<li>Antibiothérapie</li>
       <li>Amoxicilline/acide clavulanique</li>`



    ]


  },

]







